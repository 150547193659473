<template>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    :title="title"
    width="880px"
    @before-close="closeDialog"
  >
    <el-row>
      <el-col :span="24">
        <vueCropper
          style="height: 560px; width: 800px"
          ref="cropper"
          :img="value"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :canMoveBox="option.canMoveBox"
          :canMove="option.canMove"
          :centerBox="option.centerBox"
          :info="option.info"
          :fixedBox="option.fixedBox"
          :mode="option.mode"
        ></vueCropper>
      </el-col>
    </el-row>

    <template #footer>
      <div class="vab-cropper-footer">
        <el-button type="primary" @click="upload">上传并保存</el-button>
        <el-upload
          accept="image/jpeg,image/gif,image/png"
          action="#"
          :auto-upload="false"
          list-type="picture-card"
          :on-change="upLoadImg"
        >
          <template #default>
            <el-button type="primary">选择图片</el-button>
          </template>
        </el-upload>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent } from 'vue'
  import { mapActions } from 'vuex'
  import 'vue-cropper/dist/index.css'
  import { VueCropper } from 'vue-cropper'
  import { upLoadImg,upLoadAttchments } from '@/api/active'

  export default defineComponent({
    name: 'VueCropperDialog',
    components: { VueCropper },
    props: {
      title: {
        type: String,
        default: '头像裁剪',
      },
      option: {
        type: Object,
        default: {},
      },
      upLoadAttchments:{
        type:Boolean,
        default:false
      }
    },
    
    emits: ['imgInfo'],
    data() {
      return {
        dialogVisible: false,
        value: '',
        fileName: {},
        slide: {
          oriUrl: '',
          preUrl: '',
        },
        timer: null,
      }
    },
    methods: {
      ...mapActions({
        setAvatar: 'user/setAvatar',
      }),
      async upload() {
        this.$refs.cropper.getCropBlob(async (blob) => {
          var formData = new FormData()
          this.blobToDataURI(blob, async (e) => {
              formData.append('file', e)
              console.log(e)
              try {
                if(this.upLoadAttchments){  // 上传获取id
                  const { data,msg } = await upLoadAttchments(formData)
                  this.$emit('imgInfo', data)
                  this.$baseMessage(msg, 'success')
                }else{
                  const { data, msg } = await upLoadImg(formData)
                  this.$emit('imgInfo', data)
                 this.$baseMessage(msg, 'success')
                }
                this.$refs.cropper.clearCrop()
                this.dialogVisible = false
              } catch (e) {
                this.$baseMessage(e.msg, 'error')
                this.$refs.cropper.clearCrop()
                this.dialogVisible = false
              }
          })
        })
        return false
      },
      blobToDataURI(blob, callback) {
        var _this = this
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function (e) {
          let urlData = e.target.result
          var arr = urlData.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          let imgFile = new File([u8arr], _this.fileName, { type: mime })
          callback(imgFile)
        }
      },
      upLoadImg(file) {
        this.fileName = file.name
        console.log(file, this.fileName)
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(this.fileName)) {
          console.log('adasd')
          this.$message({
            message: '图片类型要求：jpeg、jpg、png',
            type: 'error',
          })
          return false
        }
        this.value = URL.createObjectURL(file.raw)
        console.log(this.value)
      },
      closeDialog() {
        this.$refs.cropper.clearCrop()
        this.dialogVisible = false
      },
    },
  })
</script>

<style lang="scss" scoped>
  :deep() {
    @media only screen and (max-width: 767px) {
      .vab-cropper-canvas {
        display: block;
        float: none;
        margin: 0 auto;
      }
      .vab-cropper-preview {
        display: none;
      }
    }

    .el-textarea {
      margin-top: $base-margin;
    }
  }
</style>
<style lang="scss">
  /* 未知原因深度选择器不好使，临时全局复写样式！！！！！！#TODO */
  .el-dialog__footer {
    height: 62px;

    &:before {
      display: block;
      clear: both;
      content: '';
    }

    > .vab-cropper-footer {
      bottom: 20px;
      > div {
        display: inline;

        .el-upload-list {
          display: none;
        }

        .el-upload--picture-card {
          float: right;
          width: auto;
          height: $base-input-height;
          line-height: $base-input-height;
          vertical-align: middle;
          background-color: transparent;
          border: 0;
          border-radius: 0;
        }

        .el-button {
          float: right;
          margin-left: 10px;
        }
      }
    }
  }
</style>
